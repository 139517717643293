import DefaultLayout from '../layout/Default';

const Form = () => {
  return (
    <DefaultLayout>
      <main className="container mx-auto px-3 pb-16">
        <h1 className="text-3xl font-bold ml-3 py-4">Get Started</h1>
        <form className="w-full max-w-lg mx-auto">
          <label className="pt-8">Name</label>
          <input
            type="text"
            className="w-full p-3 mb-4 border rounded"
          />
          <label className="pt-8">Email</label>
          <input
            type="email"
            className="w-full p-3 mb-4 border rounded"
          />
          <label className="pt-8">Password</label>
          <input
            type="password"
            className="w-full p-3 mb-4 border rounded"
          />
          <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </form>
<ul>
            <p>asdf</p>
            <li>item</li>
          </ul>
      </main>
    </DefaultLayout>
  );
};

export default Form;
