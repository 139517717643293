import './App.css';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import About from './pages/About';
import Home from './pages/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={'/'} element={<Home />}></Route>
        <Route exact path={'/about'} element={<About />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
