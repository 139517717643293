import React from 'react';

const Header = () => {
  return (
    <header className="w-full flex items-center h-16 border-b">
      <div className="container mx-auto flex justify-between items-center px-3">
        <span className="mr-2 font-bold text-2xl flex w-ful">
          <img src="logo.svg" className="w-full h-12" alt="" />
          <a href="/" className="flex w-full items-center">
            AweApp
          </a>
        </span>
      </div>
    </header>
  );
};

export default Header;
