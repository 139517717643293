import React from 'react'

const Footer = () => {
        return (
            <footer className="text-center pb-4 pt-12">
            <span className="text-xs text-center">
                &copy; WallyAX
            </span>
        </footer>
        )
}

export default Footer;